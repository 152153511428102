<template>
  <!--列表页-->
  <div style="width: 100%; background-color: #fafafa;padding-top: 30px">
    <div class="center" style="box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);">
      <div style="background-color: white" class="service_div">
        <div
            style="
           width: 1200px;
           height: 66px;
            margin: 0 auto;
            border-bottom: 1px dashed rgb(220, 220, 220);
              padding-left: 20px;
          "
            class="dp-flex flex-align-center"
        >
          <div class="classify-font">相关分类：</div>
          <span
              v-for="(item,index) in fwfl"
              :key="item.name"
              @click="fwflChange(item,index)"
              :class="['classify_span',fwflChoice === index? 'select_span' : '',]"
          >{{ item.name }}</span
          >
        </div>
      </div>
      <div style="background-color: white" v-show="firstChoose" class="service_div">
        <div
            style="  padding-left: 20px;;flex-wrap:wrap;width: 1200px;margin: 0 auto;padding-top:10px;border-bottom: 1px dashed rgb(220, 220, 220);"
            class="dp-flex flex-align-center">
          <div class="classify-font" style="margin-bottom: 10px">二级分类：</div>
          <span
              style="height: 30px;margin-bottom: 10px"
              v-for="(item,index) in fwbq"
              :key="item.name"
              @click="fwbqChange(item,index)"
              :class="['classify_span',fwbqChoice === index? 'select_span' : '',]"
          >{{ item.name }}</span
          >
        </div>
      </div>
      <div style="background-color: white" v-show="secondChoose" class="service_div">
        <div
            style="  padding-left: 20px;;flex-wrap:wrap;width: 1200px;margin: 0 auto;padding-top:10px;border-bottom: 1px dashed rgb(220, 220, 220);"
            class="dp-flex flex-align-center">
          <div class="classify-font" style="margin-bottom: 10px">三级分类：</div>
          <span
              style="height: 30px;margin-bottom: 10px"
              v-for="(item,index) in sjfl"
              :key="item.name"
              @click="sjflChange(item,index)"
              :class="['classify_span',sanjiChoice === index? 'select_span' : '',]"
          >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="sl-sort">
        <div class="dp-flex"><span>排</span><span style="width: 30px"></span><span>序：</span></div>
        <span @click="sortList('NONE')"
              style="margin-left: 20px;cursor:pointer;"
              :class="{'active' : sortField === 'NONE'}">默认</span>
        <span @click="sortList('PRICE')"
              style="margin-left: 25px;cursor:pointer;position: relative"
              :class="[{'active' : sortPriceDesc||sortPriceAsc},{'activeASC' : sortPriceDesc}]">价格
          <span style="position: absolute;top: -5px"><Icon type="md-arrow-dropup"/></span>
          <span style="position: absolute;top: 4px"><Icon type="md-arrow-dropdown"/></span>
        </span>
        <span @click="sortList('SALES')"
              style="margin-left: 45px;cursor:pointer;position: relative"
              :class="[{'active' : sortSalesDesc||sortSalesAsc},{'activeASC' : sortSalesDesc}]">销量
          <span style="position: absolute;top: -5px"><Icon type="md-arrow-dropup"/></span>
          <span style="position: absolute;top: 4px"><Icon type="md-arrow-dropdown"/></span>
        </span>
      </div>
    </div>

    <!--    <div-->
    <!--        class="center"-->
    <!--        style="-->
    <!--        height: 1200px;-->
    <!--        margin-top: 10px;-->
    <!--        background-color: rgb(250, 250, 250);-->
    <!--      "-->
    <!--        id="service_list"-->
    <!--    >-->
    <!--      <div class="dp-flex flex-wrap" style="margin-left: -10px">-->
    <!--        <div-->
    <!--            v-for="item of serviceList"-->
    <!--            :key="item.id"-->
    <!--            class="list-item"-->
    <!--            style="-->
    <!--            height: 385px;-->
    <!--            width: 232px;-->
    <!--            background-color: white;-->
    <!--            margin-left: 10px;-->
    <!--            margin-bottom: 11px;-->
    <!--          "-->
    <!--            @click="goDetail(item.id)"-->
    <!--        >-->
    <!--          <div style="height: 192px">-->
    <!--            <img :src="item.mainImageUrl" alt="" style="width: 100%;height: 100%"/>-->
    <!--          </div>-->
    <!--          <div style="height: 193px">-->
    <!--            <div style="height: 40px; padding: 10px 20px; color: #fb7b3c">-->
    <!--              <span style="font-size: 20px">{{ item.price === 0 ? "面议" : `¥ ${item.price}` }}</span>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="font-size: 14px; padding-left: 20px;padding-right: 20px; margin-bottom: 5px;    overflow: hidden;-->
    <!--    text-overflow: ellipsis;-->
    <!--    display: -webkit-box;-->
    <!--    -webkit-line-clamp: 2;-->
    <!--    -webkit-box-orient: vertical;-->
    <!--height: 42px"-->
    <!--            >-->
    <!--              {{ item.name }}-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="margin-bottom: 7px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding-right: 10px">-->
    <!--              <span style="font-size:12px;padding-left: 20px;padding-top: 5px">{{ item.labelValue }}</span>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                margin-bottom: 16px;-->
    <!--                padding-left: 20px;-->
    <!--                color: #fb7b3c;-->
    <!--                overflow: hidden;-->
    <!--                height: 30px;-->
    <!--                line-height: 30px;-->
    <!--              "-->
    <!--            >-->
    <!--              <div style="float: left;padding-top: 3px">-->
    <!--                <span-->
    <!--                    style="font-size: 12px;line-height: 15px;width: 100px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display: inline-block">{{-->
    <!--                    item.shopName-->
    <!--                  }}</span>-->
    <!--              </div>-->
    <!--              <div style="float: right">-->
    <!--                <Rate disabled v-model="item.attention"/>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                style="-->
    <!--                display: flex;-->
    <!--                align-items: center;-->
    <!--                justify-content: space-around;-->
    <!--              "-->
    <!--            >-->
    <!--              <button-->
    <!--                  class="consult-btn"-->
    <!--                  @click.stop="openZoosUrl"-->
    <!--              >-->
    <!--                立即咨询-->
    <!--              </button-->
    <!--              >-->
    <!--              <button-->
    <!--                  class="buy-btn"-->
    <!--                  style="color:#1890FF;border: 1px solid #1890FF"-->
    <!--                  @click.stop="goPay(item.id)"-->
    <!--              >-->
    <!--                立即购买-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div STYLE="overflow: hidden;width:100%">-->
    <!--        <Page-->
    <!--            :total="listTotalNum"-->
    <!--            :page-size="listQuery.pageSize"-->
    <!--            show-elevator-->
    <!--            style="margin-top: 30px; float: right; margin-bottom: 30px"-->
    <!--            @on-change="changePage"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="list-service">
      <div class="ls-body" v-for="(item,index) in serviceList" :key="index" @click="goDetail(item.id)">
        <img class="lsb-icon" :src="item.mainImageUrl"/>
        <div class="lsb-des">
          <div class="lsb-des-title ellipse-1">
            {{ item.name }}
          </div>
          <div class="lsb-des-tag">
            <div class="lsb-des-tagA">{{item.patentType}}</div>
            <div class="lsb-des-tagB">{{item.labelValue}}</div>
          </div>
          <div class="lsb-rate">
            <div>
              <span style="margin-right: 5px">{{ item.shopName }}</span>
              <Rate disabled :value="item.attention||5"/>
            </div>
          </div>
        </div>
        <div class="lsb-num">服务价格：<span style="color: rgba(254, 121, 48, 1);font-size: 24px">{{
            item.price === 0 ? "面议" : `¥ ${item.price}`
          }}</span></div>
        <div class="lsb-button">
          <div class="lsb-buttonA display-flex-center" @click.stop="openZoosUrl">立即咨询</div>
          <div class="lsb-buttonB display-flex-center" @click.stop="goPay(item.id)">立即购买</div>
        </div>
      </div>
      <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
        <Page
            :total="listTotalNum"
            :page-size="listQuery.pageSize"
            show-elevator
            @on-change="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getScienceServiceList,
  getScienceServiceLabelList,
  getClassifyCodeList
} from "@/plugins/api/scienceServiceApi";
import {getLocalToken} from "@/plugins/tools/storage";
import {sendComment, goodsRest} from "@/plugins/api/Good";
import {successToast} from "@/plugins/tools/util";
import {errorToast} from "../../../plugins/tools/util";

export default {
  name: "ScienceServiceList",
  data() {
    return {
      firstChoose: false,
      secondChoose: false,
      fwfl: [],
      fwbq: [],
      sjfl: [],
      fwbqChoice: 0,
      sanjiChoice: 0,
      fwflChoice: "",
      serviceList: [],
      listTotalNum: 0,
      listQuery: {
        code: "",
        pageSize: 4,
        pageNum: 1
      },
      isallclassify: false,
      sortField: "NONE",
      sortOrder: false,
      consultText: "",
      shopId: ""
    };
  },

  async mounted() {
    this.initNav();
  },

  computed: {
    sortPriceAsc() {
      return this.sortField === "PRICE" && this.sortOrder;
    },
    sortPriceDesc() {
      return this.sortField === "PRICE" && !this.sortOrder;
    },
    sortSalesAsc() {
      return this.sortField === "SALES" && this.sortOrder;
    },
    sortSalesDesc() {
      return this.sortField === "SALES" && !this.sortOrder;
    }
  },
  methods: {
    changeclassifyshow() {
      this.isallclassify = !this.isallclassify;
    },
    openZoosUrl() {
      openZoosUrl("chatwin");
    },
    async initNav() {
      let fl = await getClassifyCodeList({});
      if (fl.code === 0) {
        this.fwfl = [{name: "全部分类", code: ""}].concat(fl.result || []);
        this.fwbq = [{name: "全部分类", code: ""}];
        this.sjfl = [{name: "全部分类", code: ""}];
        this.getScienceServiceList();
        if (this.$route.query.sort) {
          this.fwflChange(this.fwfl[this.$route.query.sort], this.$route.query.sort)
        }
      }
    },
    async getScienceServiceList(sortField, sortOrder) {
      let param = {};
      param.code = this.listQuery.code;
      if (sortField) {
        param.sortField = sortField;
      }
      if (sortOrder) {
        param.sortOrder = sortOrder;
      }
      param = Object.assign({}, param, this.listQuery);

      let data = await getScienceServiceList(param);
      if (data.code === 0) {
        this.serviceList = data.result.list || [];
        this.listTotalNum = data.result.total;
      }

    },
    changePage(index) {
      this.listQuery.pageNum = index;
      this.getScienceServiceList(this.fwflChoice, this.fwbqChoice);
    },
    sortList(field) {
      this.sortField = field;
      this.sortOrder = !this.sortOrder;
      this.listQuery.pageNum = 1;
      this.getScienceServiceList(
          this.sortField,
          this.sortOrder ? "ASC" : "DESC"
      );
    },
    resetQuery() {
      this.listQuery.pageNum = 1;
      this.sortField = "NONE";
    },
    fwflChange(item, index) {
      this.fwflChoice = index;
      this.resetQuery();
      this.listQuery.code = item.code;
      this.getScienceServiceList();
      this.fwbq = [{name: "全部分类", code: item.code}].concat(item.children || []);
      this.sjfl = [{name: "全部分类", code: item.code}];
      this.fwbqChoice = 0;
      this.sanjiChoice = 0;
      if (item.name != "全部分类") {
        this.firstChoose = true;
        this.secondChoose = false;
      } else {
        this.firstChoose = false;
        this.secondChoose = false;
      }
    },
    sjflChange(item, index) {
      this.sanjiChoice = index;
      this.listQuery.code = item.code;
      this.resetQuery();
      this.getScienceServiceList();
    },
    fwbqChange(item, index) {
      this.fwbqChoice = index;
      this.resetQuery();
      this.sjfl = [{name: "全部分类", code: item.code}].concat(item.children || []);
      this.listQuery.code = item.code;
      this.getScienceServiceList();
      this.sanjiChoice = 0;
      if (item.name != "全部分类") {
        this.secondChoose = true;
      } else {
        this.secondChoose = false;
      }
      // let flCode = this.fwflChoice ? this.fwflChoice.code || "" : "";
      // this.$router.push({
      //   path: `serviceList?code=${flCode}&label=${item.id}`,
      // });
    },
    sendComment() {
      sendComment({
        shopId: this.shopId,
        reviewContent: this.consultText
      }).then(res => {
        if (res.code === 0)
          successToast("咨询成功");
      });
    },
    checkLogin() {
      if (!getLocalToken()) {
        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.$router.push({name: "login"});
          },
          render: (h) => {
            return h("div", [
              h(
                  "div",
                  {
                    style:
                        "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;"
                  },
                  "请先登录后再试"
              )
            ]);
          }
        });
        return false;
      } else {
        return true;
      }
    },
    consulting(item) {
      this.shopId = item.shopId;
      const state = this.checkLogin();
      if (!state) {
        return;
      }

      this.$Modal.confirm({
        draggable: true,
        onOk: async () => {
          this.sendComment();
        },
        render: (h) => {
          return h("div", [
            h(
                "div",
                {
                  style:
                      "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;"
                },
                "咨询内容"
            ),
            h("Input", {
              style: "width:100%;",
              props: {
                value: this.consultText,
                autofocus: true,
                placeholder: "请输入咨询内容",
                type: "textarea",
                autosize: true
              },
              on: {
                input: (val) => {
                  this.consultText = val;
                }
              }
            })
          ]);
        }
      });
    },


    async goPay(id) {
      let json = await goodsRest({goodsId: id, num: 1});
      if (json.code == 0) {
        if (json.result == true) {
          this.$router.push({
            path: `servicePay?goodId=${id}`
          });
        } else {
          errorToast(json.message);
        }
      } else {
        errorToast(json.message);
      }

    },
    goDetail(id) {
      this.$router.push({
        path: `serviceDetail?goodId=${id}`
      });
    }
  }
};
</script>


<style scoped lang="scss">


.service_div {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  overflow: hidden;

}

.classify_span {
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-right: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.select_span {
  background-color: rgba(15, 98, 223, 1);
  color: #FFFFFF;
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.classify-font {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  white-space: nowrap;
  margin-right: 28px;
}

.sl-sort {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  background-color: #FFFFFF;
  padding-left: 20px;
}

.active {
  color: #1890FF;
}

.active span:last-child {
  color: #333;
}

.activeASC span:first-child {
  color: #333;
}

.activeASC span:last-child {
  color: #1890FF;
}


.list-service {
  width: 1200px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 20px auto 50px auto;

}

.ls-body {
  height: 180px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding: 29px 37px 29px 20px;
  cursor: pointer;
}

.ls-body:hover {
  box-shadow: 1px 1px 10px rgba(15, 98, 223, 1);
}

.lsb-icon {
  width: 214px;
  height: 120px;
  object-fit: cover;
}

.lsb-des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 28px;
  overflow: hidden;
  width: 549px;
}

.lsb-des-title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.lsb-des-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lsb-des-tagA {
  background: #FFDBCD;
  border-radius: 4px;
  padding: 7px;
  font-size: 14px;
  font-weight: 400;
  color: #FE7930;
}

.lsb-des-tagB {
  background: #CADFFE;
  border-radius: 4px;
  padding: 7px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-left: 10px;
}

.lsb-des-title-tag {
  width: 60px;
  height: 22px;
  background: #D7F1D9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #17C22D;
  flex-shrink: 0;
  margin-left: 10px;
}

.lsb-des-tel {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.lsb-rate {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.lsb-num {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 70px;
  flex-shrink: 0;
}

.lsb-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  margin-left: 50px;
}

.lsb-buttonA {
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.lsb-buttonB {
  width: 120px;
  height: 34px;
  background: #FE7930;
  border: 1px solid #FE7930;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
